$(function() {
	// anchor scroll
	$('a[href*=\\#]').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var $target = $(this.hash);
      $target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
      if ($target.length) {
        var targetOffset = $target.offset().top;
        $('html, body').animate({scrollTop: targetOffset}, 400);
				return false;
      }
    }
  });

	// variables shared by below
	var winHeight = $(window).height(),
	winTop = $(window).scrollTop();

	// scroll line
	$(window).scroll(function() {
		winTop = $(window).scrollTop();
		var docHeight = $(document).height(),
			scrolled = (winTop/(docHeight - winHeight)) * 100;
		$('.scroll-line').css('width', (scrolled + '%'));
	});

	// add target="_blank" to uh footer links
	$('.uh-footer .nav-required a').attr('target', '_blank');
});
